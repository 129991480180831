import React from "react";
import { IonButton, IonButtons, IonIcon, IonContent, IonPage } from "@ionic/react";
import MobileSideMenu from "../../components/MobileSideMenu/MobileSideMenu";
import NavBar from "../../components/NavBar/NavBar";
import { getWindowWidth } from "../../helpers/window";
import { pageClicked } from "../../services";
import { logoLinkedin, logoInstagram } from "ionicons/icons";

import "./Contact.scss";
const Contact: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked} />
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text red">
            <span>Get in touch</span>
          </div>
          <div className="list">
            <div className="item">
              <div className="item-title">Email</div>
              <div className="item-subtitle">
                <a href="mailto: mikeyn205@gmail.com"> mikeyn205@gmail.com </a>
              </div>
            </div>
            <div className="item">
              <div className="item-title">Socials</div>
              <div className="item-subtitle">
                <IonButtons className="mobile-side-menu__social-media-buttons">
                  <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.linkedin.com/in/mikey-nissenbaum-0480b9a7/" target="_blank">
                    <IonIcon icon={logoLinkedin}></IonIcon>
                  </IonButton>
                  <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.instagram.com/mikeyniss_video/" target="_blank">
                    <IonIcon icon={logoInstagram}></IonIcon>
                  </IonButton>
                </IonButtons>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Contact;
