import { person, camera, mail } from "ionicons/icons";
import mikeyCanal from "../images/mikey-canal.jpeg";
import comedy from "../images/comedy.jpg";
import podcast from "../images/podcast.png";
import photography from "../images/photography.jpg";

interface NavItems {
  text: string;
  routerLink?: string
  icon?: string;
  subItems?: Array<{ title: string, routerLink: string }>;
}

export const navItems: NavItems[] = [
  {
    text: "About me",
    icon: person,
    routerLink: '/aboutme'
  },
  {
    text: "Works",
    icon: camera,
    subItems: [{ routerLink: '/video', title: "Video production" }, { routerLink: '/photography', title: "Photography" }, { routerLink: '/comedy', title: "Comedy production" }, { routerLink: '/podcast', title: "Podcast production" }],
  },
  { text: "Contact", icon: mail, routerLink: '/contact' },
];

export const cardItems = [
  { routerLink: '/video', text: "Video production", img: mikeyCanal },
  { routerLink: '/photography', text: "Photography", img: photography },
  { routerLink: '/comedy', text: "Comedy Production", img: comedy },
  { routerLink: '/podcast', text: "Podcast Production", img: podcast },
];
