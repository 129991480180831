import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import MobileSideMenu from "../../../components/MobileSideMenu/MobileSideMenu";
import NavBar from "../../../components/NavBar/NavBar";
import { getWindowWidth } from "../../../helpers/window";
import { pageClicked } from "../../../services";
import ConnectFooter from "../../../components/ConnectFooter/ConnectFooter";
import mikeyAward1 from "../../../images/mikey_award_1.png";
import mikeyAward2 from "../../../images/mikey_award_2.png";
import mikeyAward3 from "../../../images/mikey_award_3.png";
import mikeyAward4 from "../../../images/mikey_award_4.png";
import "./Comedy.scss";
const Comedy: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked} />
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text red">
            <span>Comedy Production</span>
          </div>
          <div className="home-content__text sub">
            <p>
              I can help your brand engage with audiences with{" "}
              <span className="home-content__text-highlighted">unique</span> and{" "}
              <span className="home-content__text-highlighted">entertainng</span> ideas.
            </p>
          </div>
          <div className="iframe-container mobile-padding">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/504094844?h=221f0b42f6"
              width="640"
              height="360"
            ></iframe>
          </div>
          <div className="two-column">
            <div className="left">
              <div className="title">
                As well as professional videography - I write, direct and edit comedy shorts, sketches and reels with a
                number of collaborators.
              </div>
              <div className="title">I have previously had shorts featured at:</div>
              <div className="subtitle">
                London Comedy Short Film Festival <br />
                <br />
                Harrogate Film Festival <br />
                <br />
                Edinburgh International Festival
              </div>
            </div>
            <div className="right">
              <div className="photo-grid">
                <div className="row">
                  <img src={mikeyAward1} />
                  <img src={mikeyAward2} />
                </div>
                <div className="row">
                  <img src={mikeyAward3} />
                  <img src={mikeyAward4} />
                </div>
              </div>
            </div>
          </div>
          <ConnectFooter />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Comedy;
