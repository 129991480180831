import React from "react";
import "./ConnectFooter.scss";
import {
    IonButtons,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { logoLinkedin, logoInstagram } from "ionicons/icons";

const ConnectFooter: React.FC = () => {
    return (
        <div className="connect-footer">
            <a href="mailto: mikeyn205@gmail.com">Get in touch, mikeyn205@gmail.com </a>
            <IonButtons className="nav-bar__button-container" slot="end">
                <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.linkedin.com/in/mikey-nissenbaum-0480b9a7/" target="_blank">
                    <IonIcon size="large" icon={logoLinkedin}></IonIcon>
                  </IonButton>
                  <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.instagram.com/mikeyniss_video/" target="_blank">
                    <IonIcon size="large" icon={logoInstagram}></IonIcon>
                  </IonButton>
            </IonButtons>
        </div>
    );
};

export default ConnectFooter;
