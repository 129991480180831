import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import MobileSideMenu from "../../../components/MobileSideMenu/MobileSideMenu";
import NavBar from "../../../components/NavBar/NavBar";
import { getWindowWidth } from "../../../helpers/window";
import { pageClicked } from "../../../services";
import ConnectFooter from "../../../components/ConnectFooter/ConnectFooter";

import "./Podcast.scss";
const Podcast: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked} />
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text red">
            <span>Podcast Production</span>
          </div>
          <div className="home-content__text sub">
            <p>
              I can provide podcast production for remote or in person recording services - along with{" "}
              <span className="home-content__text-highlighted">editing</span>,{" "}
              <span className="home-content__text-highlighted">publishing</span> and{" "}
              <span className="home-content__text-highlighted">promoting</span> via graphics and audiograms.
            </p>
          </div>
          <div className="two-column">
            <div className="left">
              <div className="title">I have done this for several series each for two podcasts</div>
              <div className="subtitle">Reflecting Value</div>
              <div className="subtitle">Frontline Podcast</div>
            </div>
            <div className="right">
              <iframe
                src="https://open.spotify.com/embed/episode/0GVlgvmWdVWXb3NOddvFD1?utm_source=generator"
                width="100%"
                height="152"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
              <iframe
                src="https://open.spotify.com/embed/episode/0a5cQnVE1RIgtRQ64cdNd7?utm_source=generator"
                width="100%"
                height="152"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="iframe-container mobile-padding">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/We3ypuBfLQg?si=08n-MAft4PgJKgCn"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          <ConnectFooter />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Podcast;
