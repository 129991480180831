import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import MobileSideMenu from "../../../components/MobileSideMenu/MobileSideMenu";
import NavBar from "../../../components/NavBar/NavBar";
import { getWindowWidth } from "../../../helpers/window";
import { pageClicked } from "../../../services";
import ConnectFooter from "../../../components/ConnectFooter/ConnectFooter";
import mikeyLogos from "../../../images/mikey_logos.jpeg";

import "./Video.scss";
const Video: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked} />
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text red">
            <span>Video Production</span>
          </div>
          <div className="home-content__text sub">
            <p>
              I can help your <span className="home-content__text-highlighted">brand</span>,{" "}
              <span className="home-content__text-highlighted">organisation</span>,
              <span className="home-content__text-highlighted"> campaign</span> tell its story through video.
            </p>
          </div>
          <div className="two-column mobile-reverse">
            <div className="left">
              <div className="title main">
                As an experienced videographer and editor I will happily work with clients from start to finish on a
                production, with quality and pace at the heart of everything I do.
              </div>
              <div className="title">Professional Videography (Mobile + DSLR)</div>
              <div className="subtitle">
                On location B-roll <br />
                <br />
                Talking head interviews <br />
                <br />
                Social first content (TikTok, Reels etc)
              </div>
              <div className="title">Editing (Adobbe Premiere)</div>
              <div className="subtitle">
                On trend styles and looks through motion and cutting <br />
                <br />
                Latest accessibility standards - accurate captions <br />
                <br />
                Sharp colour correction and grading
              </div>
            </div>
            <div className="right mobile-no-padding">
              <div className="iframe-container">

                <iframe
                  src="https://player.vimeo.com/video/805138887?h=f5e1d822f8"
                  allow="autoplay; fullscreen; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="two-column mobile-reverse">
            <div className="left">
              <img src={mikeyLogos} />
            </div>
            <div className="right">
              <div className="title">Scripting / storyboarding / conducting / interviews</div>
              <div className="title">Basic animation and MOGRTs (After Effects)</div>
              <div className="subtitle">
                Titles and graphics for your brand <br />
                <br />
                Lower third animations <br />
                <br />
                Basic animated sequences
              </div>
            </div>
          </div>
          <ConnectFooter />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Video;
