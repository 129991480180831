import React, { useState } from "react";
import "./NavBar.scss";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonMenuButton,
  IonItem,
  IonLabel,
  IonPopover,
} from "@ionic/react";
import { logoLinkedin, logoInstagram } from "ionicons/icons";
import { navItems } from "../../variables/items";
import { getWindowWidth } from "../../helpers/window";

const NavBar: React.FC<{ pageClicked: any }> = ({ pageClicked }) => {
  const windowWidth = getWindowWidth();
  const isWeb = windowWidth >= 768;
  const [popoverEvent, setPopoverEvent] = useState<Event | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [hoveredNavItem, setHoveredNavItem] = useState<number | null>(null);

  const handlePopover = (event: any, index: number) => {
    setPopoverEvent(event);
    setPopoverVisible(true);
    setHoveredNavItem(index);
  };

  const resetPopoverEvent = () => {
    setPopoverVisible(false);
    setHoveredNavItem(null);
  };

  return (
    <>
      <IonHeader className="nav-bar__header">
        <IonToolbar className="nav-bar__toolbar">
          <IonTitle className="nav-bar__toolbar-title">
            <IonButtons>
              <IonButton routerLink="/home">MN Productions</IonButton>
            </IonButtons>
          </IonTitle>
          {isWeb ? (
            <IonButtons className="nav-bar__button-container" slot="end">
              {navItems.map((navItem, index) => (
                <IonButton
                  key={index}
                  className="nav-bar__text-button"
                  onMouseEnter={(e) => {
                    if (navItem.subItems && navItem.subItems.length > 0) {
                      handlePopover(e, index);
                    }
                  }}
                  routerLink={navItem.routerLink}
                  onClick={() => pageClicked(navItem.text)}
                >
                  {navItem.text}
                </IonButton>
              ))}
              <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.linkedin.com/in/mikey-nissenbaum-0480b9a7/" target="_blank">
                <IonIcon size="large" icon={logoLinkedin}></IonIcon>
              </IonButton>
              <IonButton className="nav-bar__social-button" fill="clear" color="dark" href="https://www.instagram.com/mikeyniss_video/" target="_blank">
                <IonIcon size="large" icon={logoInstagram}></IonIcon>
              </IonButton>
            </IonButtons>
          ) : (
            <IonButtons slot="end">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      {popoverEvent && isWeb && (
        <IonPopover
          className="nav-bar__popover"
          showBackdrop={false}
          isOpen={popoverVisible}
          event={popoverEvent}
          onIonPopoverDidDismiss={resetPopoverEvent}
        >
          {navItems.map((navItem, index) => (
            <div
              key={index}
              onMouseEnter={(e) => handlePopover(e, index)}
              onMouseLeave={() => setPopoverVisible(false)}
            >
              {hoveredNavItem === index && (
                <>
                  {navItem.subItems?.map((subItem, subIndex) => (
                    <IonItem
                      key={subIndex}
                      className="nav-bar__popover-item"
                      button={true}
                      routerLink={subItem.routerLink}
                    >
                      <IonLabel className="nav-bar__popover-label">{subItem.title}</IonLabel>
                    </IonItem>
                  ))}
                </>
              )}
            </div>
          ))}
        </IonPopover>
      )}
    </>
  );
};

export default NavBar;
