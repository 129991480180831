import React, { useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { menuController } from "@ionic/core/components";
import { close, chevronForward, chevronDown } from "ionicons/icons";
import { navItems } from "../../variables/items";
import "./MobileSideMenu.scss";
import ConnectFooter from "../ConnectFooter/ConnectFooter";

const MobileSideMenu: React.FC<{ pageClicked: any }> = ({ pageClicked }) => {
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const toggleItem = (index: number) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const handleItemClick = async (text: string) => {
    await pageClicked(text);
    await menuController.close();
    setExpandedItems([]);
  };

  return (
    <IonMenu contentId="main-content" className="mobile-side-menu">
      <IonHeader className="mobile-side-menu__header">
        <IonToolbar>
          <IonButtons slot="end">
            <IonMenuToggle>
              <IonButton fill="clear" color="dark">
                <IonIcon size="large" icon={close}></IonIcon>
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonList className="mobile-side-menu__list">
        {navItems.map((navItem, index) => (
          <React.Fragment key={index}>
            <IonItem
              className="mobile-side-menu__list-item"
              button={true}
              routerLink={navItem.routerLink}
              onClick={() => (navItem.subItems ? toggleItem(index) : handleItemClick(navItem.text))}
            >
              <IonIcon icon={navItem.icon} className="mobile-side-menu__list-item-icon"></IonIcon>
              <IonLabel>{navItem.text}</IonLabel>
              {navItem.subItems && (
                <IonIcon icon={expandedItems.includes(index) ? chevronDown : chevronForward}></IonIcon>
              )}
            </IonItem>
            {expandedItems.includes(index) && navItem.subItems && (
              <IonList className="mobile-side-menu__subitem-list">
                {navItem.subItems.map((subItem, subIndex) => (
                  <IonItem
                    key={subIndex}
                    className="mobile-side-menu__subitem"
                    routerLink={subItem.routerLink}
                    onClick={() => handleItemClick(subItem.title)}
                    button={true}
                  >
                    <IonLabel>{subItem.title}</IonLabel>
                  </IonItem>
                ))}
              </IonList>
            )}
          </React.Fragment>
        ))}
      </IonList>
      <ConnectFooter/>
    </IonMenu>
  );
};

export default MobileSideMenu;
