import React from "react";
import { IonContent, IonPage, IonRouterLink } from "@ionic/react";
import "./Home.scss";
import { cardItems } from "../../variables/items";
import MobileSideMenu from "../../components/MobileSideMenu/MobileSideMenu";
import ConnectFooter from "../../components/ConnectFooter/ConnectFooter";
import NavBar from "../../components/NavBar/NavBar";
import { getWindowWidth } from "../../helpers/window";
import { pageClicked } from "../../services";
const Home: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  


  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked}/>
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text">
              <p>Hi! I am <span className="home-content__text-highlighted">Mikey Nissenbaum</span>, a freelance videographer, editor & content creator.</p>
          </div>
          <div className="home-content__cards-container" >
            {cardItems.map((cardItem, index) => (
              
              <div key={index} className="home-content__card" color="light">
                <img src={cardItem.img} alt={cardItem.text} />
                  <div className="home-content__card-header">{cardItem.text}
                  <IonRouterLink routerLink={cardItem.routerLink} />
                  </div>
                  <div className={`home-content__card-background ${index === cardItems.length - 1 ? 'last' : ''}`}></div>
              </div>
                
            ))}
          </div>
          <ConnectFooter />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
