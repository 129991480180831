import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import MobileSideMenu from "../../../components/MobileSideMenu/MobileSideMenu";
import NavBar from "../../../components/NavBar/NavBar";
import { getWindowWidth } from "../../../helpers/window";
import { pageClicked } from "../../../services";
import "./Photography.scss";
import ConnectFooter from "../../../components/ConnectFooter/ConnectFooter";
import bestMan from "../../../images/best-man-speech.jpeg";
import blondeSmile from "../../../images/blonde-smile.jpeg";
import friends from "../../../images/friends.jpeg";
import gig from "../../../images/gig.jpeg";
import gig2 from "../../../images/gig2.jpeg";
import gig3 from "../../../images/gig3.jpeg";
import gig4 from "../../../images/gig4.jpeg";
import gig5 from "../../../images/gig5.jpeg";
import gig6 from "../../../images/gig6.jpeg";
import graffiti from "../../../images/graffiti.jpeg";
import madHatter from "../../../images/mad-hatter.jpeg";
import makingBoat from "../../../images/making-boat.jpeg";
import manGraffiti from "../../../images/man-graffiti.jpeg";
import manSmiling from "../../../images/man-smiling.jpeg";
import manSign from "../../../images/man-with-sign.jpeg";
import otherLondonEyeSmile from "../../../images/other-smile-london-eye.jpeg";
import plant from "../../../images/plant.jpeg";
import pubQuiz from "../../../images/pub-quiz.jpeg";
import skating from "../../../images/skating.jpeg";
import tableSmile from "../../../images/table-smile.jpeg";
import thinkingEmoji from "../../../images/thinking-emoji.jpeg";
import womenSign from "../../../images/women-sign.jpeg";
import workConference from "../../../images/work-conference.jpeg";
import wowTable from "../../../images/wow-table.jpeg";
import smileLondonEye from "../../../images/smile-london-eye.jpeg";

const images = [
  manGraffiti,
  gig2,
  tableSmile,
  blondeSmile,
  manSign,
  friends,
  womenSign,
  wowTable,
  manSmiling,
  workConference,
  pubQuiz,
  thinkingEmoji,
  bestMan,
  smileLondonEye,
  otherLondonEyeSmile,
  gig6,
  gig,
  gig5,
  gig3,
  gig4,
  graffiti,
  madHatter,
  skating,
  plant,
  makingBoat,
];

const Photography: React.FC = () => {
  const windowWidth = getWindowWidth();
  const isMobileOrTablet = windowWidth < 768;

  return (
    <>
      {isMobileOrTablet && <MobileSideMenu pageClicked={pageClicked} />}
      <IonPage id="main-content">
        <NavBar pageClicked={pageClicked} />
        <IonContent className="home-content" fullscreen={true}>
          <div className="home-content__text red">Photography</div>
          <div className="home-content__text sub">
            <p>
              I can provide general photography and portraits at your work events or gigs with{" "}
              <span className="home-content__text-highlighted">pace</span> and{" "}
              <span className="home-content__text-highlighted">quality</span>, ready for socials and print.
            </p>
          </div>
          <div className="photography__image-container">
            {images.map((image, index) => (
              <img key={index} className="photography__image" src={image} />
            ))}
          </div>
          <ConnectFooter />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Photography;
